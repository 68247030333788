<template>
	<v-container
		v-bind="{ [`grid-list-md`]: true }"
		px-0>
		<v-layout
			row
			wrap
			fill-height>
			<v-flex
				v-for="item in featuredWorkItems"
				:key="item.index"
				xs12
				sm4>
				<router-link
					tag="a"
					:to="{ name: 'carousel', params: { slug: item.slug } }">
					<v-card height="100%">
						<v-img
							class="white--text"
							height="200px"
							:src="item.image" />
						<v-card-title>
							<div>
								<h3>{{ item.title }}</h3>
								<span class="grey--text">{{ item.intro }}</span>
							</div>
						</v-card-title>
						<v-card-actions>
							<v-btn
								small
								flat
								color="primary"
								:to="{ name: 'carousel', params: { slug: item.slug } }"
								style="position: absolute; bottom: 0; right: 0; margin: 8px">
								Explore
								<v-icon class="ml-1"> exit_to_app </v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</router-link>
			</v-flex>
			<v-flex
				xs12
				text-xs-right>
				<v-btn
					to="/work"
					color="primary">
					Explore more work
					<v-icon class="ml-2"> arrow_forward_ios </v-icon>
				</v-btn>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
// import { mapState, mapMutations } from 'vuex';
import { mapState } from 'vuex';

export default {
	data: () => ({
		size: 'sm',
	}),
	computed: {
		...mapState(['featuredWorkItems']),
	},
	mounted() {
		// this.setPathSourceName();
	},
	methods: {
		/* ...mapMutations([
				'SET_PATH_SOURCE_NAME'
				]),
			setPathSourceName: function () {
				this.SET_PATH_SOURCE_NAME('home');
			}, */
	},
};
</script>

<style>
.v-card__actions {
	height: 50px;
}
</style>
