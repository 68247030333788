<template>
	<v-toolbar
		color="grey"
		flat
		dark
		fixed
		app
		class="toolbar">
		<v-toolbar-title> Kim De Vylder </v-toolbar-title>

		<v-spacer class="hidden-xs-only" />

		<v-toolbar-items
			v-if="showNavigation"
			class="hidden-xs-only">
			<v-btn
				v-for="item in navigationItems"
				:key="item.text"
				flat
				:to="{ path: item.route }">
				<v-icon class="mr-2"> {{ item.icon }} </v-icon>{{ item.text }}
			</v-btn>
		</v-toolbar-items>
	</v-toolbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	components: {},
	data: () => ({}),
	computed: {
		...mapState(['navigationItems', 'showNavigation']),
	},
	mounted() {},
	created() {},
	methods: {},
};
</script>

<style></style>
