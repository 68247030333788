<template>
	<v-app id="app">
		<v-content class="grey_vlight">
			<transition name="toolbar-anim">
				<Toolbar v-if="showToolbar" />
			</transition>
			<transition name="router-anim">
				<router-view />
			</transition>
			<transition name="bottom-nav-anim">
				<bottomNav v-if="showBottomNav" />
			</transition>
		</v-content>
	</v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar';
import BottomNav from '@/components/BottomNav';
import { mapState } from 'vuex';

export default {
	name: 'App',
	components: {
		Toolbar,
		BottomNav,
	},
	data: () => ({}),
	computed: {
		...mapState(['showToolbar', 'showBottomNav']),
	},
	created() {
		this.$store.dispatch('getNavigationItems');
		this.$store.dispatch('getLimitedNavigationItems');
		this.$store.dispatch('getWorkItems');
		this.$store.dispatch('getFeaturedWorkItems');
		this.$store.dispatch('getSkills');
		this.$store.dispatch('getFeaturedSkills');

		this.$vuetify.theme.primary = '#00ACC1';
		this.$vuetify.theme.primary_dark = '#006064';
		this.$vuetify.theme.primary_light = '#26C6DA';
		this.$vuetify.theme.grey = '#37474F';
		this.$vuetify.theme.grey_dark = '#000a12';
		this.$vuetify.theme.grey_light = '#CFD8DC';
		this.$vuetify.theme.grey_vlight = '#FAFAFA';
		this.$vuetify.theme.white = '#ffffff';
		this.$vuetify.theme.secondary = '#2B3940';
		this.$vuetify.theme.secondary_dark = '#000a12';
		this.$vuetify.theme.secondary_light = '#4f5b62';
		this.$vuetify.theme.error = '#e53935';
		this.$vuetify.theme.success = '#43a047';
		this.$vuetify.theme.warning = '#ffb300';
		this.$vuetify.theme.info = '#039be5';
	},
	methods: {},
};
</script>

<style>
body {
	-webkit-overflow-scrolling: touch;
}

.router-anim-enter-active {
	animation: routePageComing 0.3s;
	opacity: 0;
}

.router-anim-leave-active {
	animation: routePagegoing 0.3s;
	position: absolute;
}

@keyframes routePageComing {
	from {
		transform: translateX(100vw);
		opacity: 1;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes routePagegoing {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100vw);
	}
}

.toolbar-anim-enter-active {
	animation: toolbarComing 0.3s;
}

.toolbar-anim-leave-active {
	animation: toolbargoing 0.3s;
}

@keyframes toolbarComing {
	from {
		transform: translateY(-45px);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes toolbargoing {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-45px);
	}
}

.bottom-nav-anim-enter-active {
	animation: bottomNavComing 0.3s;
}

.bottom-nav-anim-leave-active {
	animation: bottomNavGoing 0.3s;
}

@keyframes bottomNavComing {
	from {
		transform: translateY(45px);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes bottomNavGoing {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(45px);
	}
}

a {
	text-decoration: none;
}
</style>
