<template>
	<v-container>
		<v-layout
			justify-center
			align-center>
			<v-btn
				v-for="item in limitedNavigationItems"
				:key="item.index"
				outline
				flat
				color="primary"
				:to="{ path: item.route }">
				<v-icon class="mr-2 hidden-xs-only"> {{ item.icon }} </v-icon
				>{{ item.text }}
			</v-btn>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data: () => ({}),
	computed: {
		...mapState(['limitedNavigationItems']),
	},
	mounted() {},
	methods: {},
};
</script>

<style></style>
