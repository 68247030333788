const navigationItems = [
	{ text: 'Home', icon: 'home', route: '/' },
	{ text: 'About', icon: 'person', route: '/about' },
	{ text: 'Work', icon: 'book', route: '/work' },
	{ text: 'Skills', icon: 'bookmarks', route: '/skills/web' },
];

const limitedNavigationItems = [
	{ text: 'About', icon: 'person', route: '/about' },
	{ text: 'Work', icon: 'book', route: '/work' },
	{ text: 'Skills', icon: 'bookmarks', route: '/skills/web' },
];

const work = {
	web: {
		category: 'Web',
		items: [
			{
				title: 'Spacewell',
				slug: 'spacewell',
				intro:
					'A corporate Wordpress website with software subscriptions Ecommerse checkout. (Webmaster)',
				description:
					'<p>A corporate website build with Wordpress CMS, the DIVI theme and Page Builder. I inherited the website when I started the job. It was in desperate need of a basic URL architecture, performance optimization, technical SEO, UX and mobile optimization.</p><p>I also expanded the website with an Ecommerce checkout system for software subscriptions and numerous other custom build features.</p><p>Currently I am developing a new Wordpress website for Spacewell with GeneratePress and custom.</p>',
				roles: {
					intro: '',
					items: [
						{
							title: 'Webmaster',
							subtitle: '',
						},
						{
							title: 'Ecommerce checkout system',
							subtitle:
								'Woocommerce, Stripe, Woocommerce Subscriptions, etc...',
						},
						{
							title: 'Content management',
							subtitle: '',
						},
						{
							title: 'Child theme development',
							subtitle: '',
						},
						{
							title: 'Custom widget development',
							subtitle: '',
						},
						{
							title: 'Custom javascript/jQuery development',
							subtitle:
								'For example: Script to show Webinar start date/time in the users local time.',
						},
						{
							title:
								'New Wordpress build with GeneratePress and custom build blocks',
							subtitle: '',
						},
						{
							title: 'General advice ',
							subtitle: 'UX, SEO, design, best practices, etc...',
						},
						{
							title: 'Reporting',
							subtitle:
								'Google Analitycs, Google Search Console, Google Data Studio',
						},
					],
				},
				features: [],
				roadmap: [],
				image: '/images/spacewell.jpg',
				path: '/work/spacewell',
				url: 'https://www.spacewell.com',
			},
			{
				title: 'Pioneer DJ',
				slug: 'pioneer-dj',
				intro:
					'A global platform for pioneerdj.com running on Sitecore. (Team)',
				description:
					'<p>A global platform for pioneerdj.com running on a large-scale global Azure infrastructure using the latest Sitecore technology. MVP went live 15 weeks after project kick-off. </p><p>All functional roles (UX, platform architecture, design, rich content, copy, as well as front and back-end development) were part of a single Agile team.</p>',
				roles: {
					intro: '',
					items: [
						{
							title: 'Content management',
							subtitle: '',
						},
						{
							title: 'Localisation management',
							subtitle: '',
						},
						{
							title: 'Client support',
							subtitle: '',
						},
						{
							title: 'Landing page development',
							subtitle: 'HTML, Bootstrap',
						},
						{
							title: 'General advice ',
							subtitle:
								'Technical, performance, marketing, CMS, best practices, etc...',
						},
						{
							title: 'Social media management',
							subtitle: '',
						},
						{
							title: 'Analytics',
							subtitle: '',
						},
						{
							title: 'Reporting',
							subtitle: 'Trend & performance',
						},
						{
							title: 'Online advertising',
							subtitle: 'Assistance',
						},
					],
				},
				features: [],
				roadmap: [],
				image: '/images/pdj.jpg',
				path: '/work/pioneer-dj',
				url: 'https://www.pioneerdj.com',
			},
			{
				title: 'Listrian',
				slug: 'listrian',
				intro: 'Laravel/vue.js web app using the Spotify API. (Personal)',
				description:
					'<p>An extensive web app build on the Spotify API for creating interactive music playlists. The first prototype was built on a basic PHP MVC system using standard JavaScript and jQuery on the front-end. For the production build, I moved to Laravel for the back-end and vue.js on the front.</p><p>Listrian.com is still in Alpha and not yet thoroughly tested or debugged.</p>',
				roles: {},
				features: [
					{
						title: 'User management',
					},
					{
						title: 'Social Media logins',
					},
					{
						title: 'CRUD lists',
					},
					{
						title: '4 ways to add tracks to lists',
					},
					{
						title: 'Track voting',
					},
					{
						title: 'Administration',
					},
					{
						title: 'Preview songs',
					},
					{
						title: 'Image upload and processing',
					},
					{
						title: 'Automatic list image creation',
					},
					{
						title: 'Automatic user avatar creation',
					},
					{
						title: 'SEO',
					},
					{
						title: 'og:meta',
					},
				],
				roadmap: [
					{
						name: 'Commenting',
					},
					{
						name: 'List player',
					},
					{
						name: 'Profiles',
					},
					{
						name: 'Suggestions',
					},
					{
						name: 'Friends/followers',
					},
				],
				image: '/images/listrian.jpg',
				path: '/work/listrian',
				// url: 'https://www.listrian.com'
			},
			{
				title: 'Spotrian',
				slug: 'spotrian',
				intro: 'Prototype for a social travel planning web app. (Personal)',
				description:
					'<p>A first setup for a social travel-planning web app. It uses the Google Maps API and is built with Laravel and Vue.js for the front-end framework.</p><p>The current version of spotrian.com features only some of the basic functionalities and is not yet thoroughly tested or debugged.</p>',
				roles: {},
				features: [
					{
						title: 'User management',
					},
					{
						title: 'Social Media logins',
					},
					{
						title: 'Search for places',
					},
					{
						title: 'Select a place',
					},
					{
						title: 'CRUD spots',
					},
					{
						title: 'Image upload and processing',
					},
					{
						title: 'SEO',
					},
					{
						title: 'og:meta',
					},
				],
				roadmap: [
					{
						name: 'Everything else',
					},
				],
				image: '/images/spotrian.jpg',
				path: '/work/spotrian',
				// url: 'https://www.spotrian.com'
			},
			{
				title: 'DJsounds',
				slug: 'djsounds',
				intro:
					'Drupal build Vlog for the DJing community owned by Pioneer DJ. (Team)',
				description:
					'<p>Drupal build Vlog for the DJing community owned by Pioneer DJ. Video content was supplied by local teams based in Ibiza, the UK, Germany and the Benelux. DJsounds.com also had a user section where DJs could submit their own DJ performance videos. Feedback on the video and DJ performance would then be provided in a comment on YouTube. The best submissions were featured on the website’s homepage.</p>',
				roles: {
					intro: '',
					items: [
						{
							title: 'Content management and coordination',
							subtitle: '',
						},
						{
							title: 'Client communication and support',
							subtitle: '',
						},
						{
							title: 'General advice',
							subtitle:
								'Technical, performance, marketing, CMS, best practices, etc...',
						},
						{
							title: 'Managing and processing user submissions',
							subtitle: '',
						},
					],
				},
				features: [],
				roadmap: [],
				image: '/images/djsounds.jpg',
				path: '/work/djsounds',
				url: 'https://www.djsounds.com',
			},
			{
				title: 'Kim De Vylder',
				slug: 'kim-de-vylder',
				intro:
					'Vue CLI, Vue Router, Vuex and separation of front and backend. (Personal)',
				description:
					'<p>My first venture into Vue CLI, Vue Router and Vuex. For now, kimdevylder.be only has a front-end section which I built while on vacation. All content is being read from a JavaScript file. In the near future, I plan to get this website “Server Site Rendered” to optimise SEO, have a back-end API with and administration for basic content CRUD functions while keeping front and back-end separated.</p>',
				roles: {},
				features: [
					{
						title: 'Vue CLI front-end',
					},
					{
						title: 'Use of Vue Router',
					},
					{
						title: 'State management with Vuex',
					},
				],
				roadmap: [
					{
						name: 'Server Site Rendering (SSR)',
					},
					{
						name: 'Backend API',
					},
					{
						name: 'CRUD',
					},
					{
						name: 'Vuetify A La Carte',
					},
				],
				image: '/images/kdv.jpg',
				path: '/work/kim-de-vylder',
				url: 'http://www.kimdevylder.be',
			},
		],
	},
	other: {
		category: 'Other',
		items: [
			{
				title: 'The Yard',
				slug: 'the-yard',
				intro: 'Virtual Reality experience build for Sansar. (Personal)',
				description:
					'<p>The Yard is a Virtual Reality experience built for Sansar. As an early adaptor and VR enthusiast, I quickly felt the urge to create for VR. I used this project to get acquainted with Blender and to take my first steps in 3D modelling, animating, texturing and exporting for games.</p>',
				roles: {},
				features: [
					{
						title: 'In-scene Youtube video display',
					},
					{
						title: 'Spacial audio',
					},
					{
						title: 'User controllable lighting',
					},
					{
						title: 'Animated light switches and buttons',
					},
					{
						title: 'Sit-points on Chairs',
					},
					{
						title: 'Cloth simulated decoration',
					},
					{
						title: 'Controlled spawning of object',
					},
				],
				roadmap: [],
				image: '/images/yard.jpg',
				path: '/work/the-yard',
				url: 'https://atlas.sansar.com/experiences/sanka/the-yard',
			},
		],
	},
};

const featuredWork = [
	{
		title: 'Spacewell',
		slug: 'spacewell',
		intro: 'A corporate Wordpress website. (Webmaster)',
		image: '/images/spacewell.jpg',
		path: '/work/spacewell',
		url: 'https://www.spacewell.com',
	},
	{
		title: 'Pioneer DJ',
		slug: 'pioneer-dj',
		intro: 'A global platform for pioneerdj.com running on Sitecore. (Team)',
		image: '/images/pdj.jpg',
		path: '/work/pioneer-dj',
		url: 'https://www.pioneerdj.com',
	},
	{
		title: 'Listrian',
		slug: 'listrian',
		intro: 'Laravel/vue.js web app using the Spotify API. (Personal)',
		image: '/images/listrian.jpg',
		path: '/work/listrian',
		url: 'https://www.listrian.com',
	},
];

const skills = {
	web: {
		category: 'Web',
		items: [
			{
				name: 'PHP',
				level: 4,
			},
			{
				name: 'HTML',
				level: 4,
			},
			{
				name: 'Javascript',
				level: 4,
			},
			{
				name: 'CSS',
				level: 4,
			},
			{
				name: 'Wordpress',
				level: 4,
			},
			{
				name: 'Tailwind CSS',
				level: 4,
			},
			{
				name: 'Bootstrap',
				level: 4,
			},
			{
				name: 'Vue.js',
				level: 4,
			},
			{
				name: 'MySQL',
				level: 4,
			},
			{
				name: 'Git',
				level: 4,
			},
			{
				name: 'Sitecore',
				level: 4,
			},
			{
				name: 'MVC',
				level: 4,
			},
			{
				name: 'Image processing',
				level: 4,
			},
			{
				name: 'SEO (Technical)',
				level: 4,
			},
			{
				name: 'Vuetify',
				level: 4,
			},
			{
				name: 'Video processing',
				level: 3,
			},
			{
				name: 'Drupal',
				level: 3,
			},
			{
				name: 'Joomla',
				level: 3,
			},
			{
				name: 'jQuery',
				level: 3,
			},
			{
				name: 'SASS',
				level: 3,
			},
			{
				name: 'UX',
				level: 3,
			},
			{
				name: 'NPM',
				level: 3,
			},
			{
				name: 'Composer',
				level: 3,
			},
			{
				name: 'Typescript',
				level: 2,
			},
			{
				name: 'Node.js',
				level: 2,
			},
		],
	},
	tools: {
		category: 'Tools',
		items: [
			{
				name: 'Google Sheets',
				level: 4,
			},
			{
				name: 'Google Data Studio',
				level: 4,
			},
			{
				name: 'Trello',
				level: 4,
			},
			{
				name: 'Sublime Text',
				level: 4,
			},
			{
				name: 'MailChimp',
				level: 4,
			},
			{
				name: 'Google Optimize',
				level: 3,
			},
			{
				name: 'BrowserStack',
				level: 3,
			},
			{
				name: 'Google Analytics',
				level: 3,
			},
			{
				name: 'Bitbucket',
				level: 3,
			},
			{
				name: 'Sourcetree',
				level: 3,
			},
			{
				name: 'MAMP Pro',
				level: 3,
			},
			{
				name: 'OSX Terminal',
				level: 3,
			},
			{
				name: 'Google Ads',
				level: 2,
			},
			{
				name: 'Facebook Ads',
				level: 2,
			},
		],
	},
	media: {
		category: 'Media',
		items: [
			{
				name: 'Illustrator',
				level: 4,
			},
			{
				name: 'Photoshop',
				level: 4,
			},
			{
				name: 'Logic Pro',
				level: 4,
			},
			{
				name: 'After Effects',
				level: 3,
			},
			{
				name: 'Indesign',
				level: 3,
			},
			{
				name: 'Blender',
				level: 3,
			},
			{
				name: 'Cinema 4D',
				level: 2,
			},
			{
				name: 'Unity3D',
				level: 2,
			},
			{
				name: 'Unreal Engine 5',
				level: 2,
			},
		],
	},
	other: {
		category: 'Other',
		items: [
			{
				name: 'Social media marketing',
				level: 3,
			},
			{
				name: 'Social media management',
				level: 3,
			},
			{
				name: 'Graphic design',
				level: 3,
			},
			{
				name: 'Web design',
				level: 3,
			},
			{
				name: 'Marketing',
				level: 3,
			},
			{
				name: 'E-commerce',
				level: 3,
			},
		],
	},
};

const featuredSkills = {
	web: {
		category: 'Web',
		items: [
			{
				name: 'Wordpress',
				level: 4,
			},
			{
				name: 'PHP',
				level: 4,
			},
			{
				name: 'Javascript',
				level: 4,
			},

			{
				name: 'Tailwind CSS',
				level: 4,
			},
			{
				name: 'Vue.js',
				level: 4,
			},
			{
				name: 'SEO (technical)',
				level: 4,
			},
		],
	},
	tools: {
		category: 'Tools',
		items: [
			{
				name: 'Google Sheets',
				level: 4,
			},
			{
				name: 'Google Data Studio',
				level: 4,
			},
			{
				name: 'Google Analytics',
				level: 3,
			},
			{
				name: 'OSX Terminal',
				level: 3,
			},
			{
				name: 'Bitbucket',
				level: 3,
			},
			{
				name: 'Google Ads',
				level: 2,
			},
		],
	},
	media: {
		category: 'Media',
		items: [
			{
				name: 'Illustrator',
				level: 4,
			},
			{
				name: 'Photoshop',
				level: 4,
			},
			{
				name: 'After Effects',
				level: 3,
			},
			{
				name: 'Indesign',
				level: 3,
			},
			{
				name: 'Blender',
				level: 3,
			},
			{
				name: 'Logic Pro',
				level: 4,
			},
		],
	},
};

export default {
	getNavigationItems() {
		return navigationItems;
	},
	getLimitedNavigationItems() {
		return limitedNavigationItems;
	},
	getWorkItems() {
		return work;
	},
	getFeaturedWorkItems() {
		return featuredWork;
	},
	getSkills() {
		return skills;
	},
	getFeaturedSkills() {
		return featuredSkills;
	},
};
