import Vue from 'vue'
import Vuex from 'vuex'

import data from '@/api/data'

Vue.use(Vuex)



export default new Vuex.Store({
	state: { // Data
		navigationItems: [],
		limitedNavigationItems: [],
		workItems: [],
		featuredWorkItems: [],
		skills: [],
		featuredSkills: [],

		showNavigation: true,
		showToolbar: false,
		showBottomNav: false,
		// showDialog: false,

		workItemTitle: '',
		workItemURL: '',
		workItemSlug: '',
		workItemSlugs: '',
		workPage: 8,
		// pathSourceName: 'work'
	},
	mutations: { // Used for changing the state
		SET_LIMITED_NAVIGATION_ITEMS: (state) => {
			state.limitedNavigationItems = data.getLimitedNavigationItems();
		},
		SET_NAVIGATION_ITEMS: (state) => {
			state.navigationItems = data.getNavigationItems();
		},
		SET_WORK_ITEMS: (state) => {
			state.workItems = data.getWorkItems();
		},
		SET_FEATURED_WORK_ITEMS: (state) => {
			state.featuredWorkItems = data.getFeaturedWorkItems();
		},
		SET_SKILLS: (state) => {
			state.skills = data.getSkills();
		},
		SET_FEATURED_SKILLS: (state) => {
			state.featuredSkills = data.getFeaturedSkills();
		},
		TOGGLE_NAVIGATION: (state, navigationState) => {
			state.showNavigation = navigationState;
		},
		TOGGLE_TOOLBAR: (state, toolbarState) => {
			state.showToolbar = toolbarState;
		},
		TOGGLE_BOTTOMNAV: (state, bottomNavState) => {
			state.showBottomNav = bottomNavState;
		},
		/* TOGGLE_DIALOG: (state, dialogState) => {
			state.showDialog = dialogState;
		}, */
		SET_WORK_ITEM_TITLE: (state, workItemTitleState) => {
			state.workItemTitle = workItemTitleState;
		},
		SET_WORK_ITEM_URL: (state, workItemUrlState) => {
			state.workItemURL = workItemUrlState;
		},
		SET_WORK_ITEM_SLUGS: (state, workItemSlugsState) => {
			state.workItemSlugs = workItemSlugsState;
		},
		SET_WORK_ITEM_SLUG: (state, workItemSlugState) => {
			state.workItemSlug = workItemSlugState;
		},
		SET_WORK_PAGE: (state, workPageState) => {
			state.workPage = workPageState;
		},
		/* SET_PATH_SOURCE_NAME: (state, pathSourceNameState) => {
			state.pathSourceName = pathSourceNameState;
		}, */
	},
	actions: { // Methods
		getLimitedNavigationItems (context) {
			context.commit('SET_LIMITED_NAVIGATION_ITEMS')
		},
		getNavigationItems (context) {
			context.commit('SET_NAVIGATION_ITEMS')
		},
		getWorkItems (context) {
			context.commit('SET_WORK_ITEMS')
		},
		getFeaturedWorkItems (context) {
			context.commit('SET_FEATURED_WORK_ITEMS')
		},
		getSkills (context) {
			context.commit('SET_SKILLS')
		},
		getFeaturedSkills (context) {
			context.commit('SET_FEATURED_SKILLS')
		},

	},
	getters: { // Computed properties
	}
})
