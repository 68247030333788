<template>
	<v-layout
		v-resize="onResize"
		row
		wrap>
		<v-flex
			xs12
			sm6
			:mb-4="marginBottom">
			<v-layout
				:justify-end="justifyEnd"
				:justify-center="justifyCenter"
				row
				fill-height
				pr-2>
				<v-avatar
					:size="avatarSize"
					color="grey lighten-4">
					<img
						src="images/kim.jpg"
						alt="avatar" />
				</v-avatar>
			</v-layout>
		</v-flex>
		<v-flex
			xs12
			sm6>
			<v-layout
				align-center
				row
				fill-height
				pl-2>
				<v-flex
					xs12
					sm8
					class="text-xs-center text-sm-left">
					<h1 style="white-space: nowrap">Kim De Vylder</h1>
					<p>Creative web/digital media all-rounder.</p>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data: () => ({
		avatarSize: 200,
		justifyEnd: true,
		justifyCenter: false,
		marginBottom: false,
	}),
	watch: {
		'$vuetify.breakpoint.width'() {
			this.onResize();
		},
	},
	mounted() {
		this.onResize();
	},
	methods: {
		onResize: function () {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					this.avatarSize = 150;
					this.justifyEnd = false;
					this.justifyCenter = true;
					this.marginBottom = true;
					break;
				default:
					this.avatarSize = 200;
					this.justifyEnd = true;
					this.justifyCenter = false;
					this.marginBottom = false;
			}
		},
	},
};
</script>

<style></style>
