<template>
	<v-bottom-nav
		:value="true"
		color="grey"
		dark
		fixed
		class="hidden-sm-and-up">
		<v-btn
			v-for="item in navigationItems"
			:key="item.text"
			flat
			:to="{ path: item.route }">
			<v-icon class="mr-2">
				{{ item.icon }}
			</v-icon>
		</v-btn>
	</v-bottom-nav>
</template>

<script>
import { mapState } from 'vuex';

// import data from '../api/data';

export default {
	components: {},
	data: () => ({}),
	computed: {
		...mapState(['navigationItems']),
	},
	mounted() {},
	created() {},
	methods: {},
};
</script>

<style></style>
