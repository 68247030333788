<template>
	<v-container
		v-bind="{ [`grid-list-md`]: true }"
		px-0>
		<v-layout
			row
			wrap
			fill-height>
			<v-flex
				v-for="(category, index) in featuredSkills"
				:key="category.index"
				xs12
				sm4>
				<v-card>
					<v-card-title>
						<h3>{{ category.category }}</h3>
					</v-card-title>
					<v-card-text>
						<v-list>
							<template v-for="(skill, i) in category.items">
								<v-list-tile :key="skill.name">
									<v-list-tile-content>
										<v-list-tile-title>{{ skill.name }}</v-list-tile-title>
									</v-list-tile-content>

									<v-list-tile-action class="hidden-sm-and-up">
										<div style="white-space: nowrap">
											<span
												v-for="ii in 5"
												:key="ii">
												<v-icon v-if="ii <= skill.level"
													>radio_button_checked</v-icon
												>
												<v-icon v-else>radio_button_unchecked</v-icon>
											</span>
										</div>
									</v-list-tile-action>
								</v-list-tile>
								<v-divider
									v-if="i + 1 < category.items.length"
									:key="i" />
							</template>
						</v-list>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn
							flat
							small
							color="primary"
							:to="{ path: '/skills/' + index }">
							Show more
							<v-icon class="ml-1"> exit_to_app </v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-flex>
			<v-flex
				xs12
				text-xs-right>
				<v-btn
					to="/skills/web"
					color="primary">
					Explore more skills
					<v-icon class="ml-2"> arrow_forward_ios </v-icon>
				</v-btn>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data: () => ({
		size: 'sm',
	}),
	computed: {
		...mapState(['featuredSkills']),
	},
};
</script>

<style></style>
