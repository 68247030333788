import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
		},
		{
			path: '/about',
			name: 'about',
			component: () =>
				import(/* webpackChunkName: "about" */ './views/About.vue'),
		},
		{
			path: '/skills',
			name: 'skills',
			component: () => import('./views/Skills.vue'),
			children: [
				{
					path: '/skills/:cat',
					name: 'skillsTab',
					component: () => import('./components/skills/Index.vue'),
				},
			],
		},
		{
			path: '/work',
			name: 'work',
			component: () => import('./views/Work.vue'),
		},
		{
			path: '/work/:slug',
			name: 'carousel',
			component: () =>
				import(/* webpackChunkName: "about" */ './views/Carousel.vue'),
		},
	],
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});
