<template>
	<div style="margin-bottom: 50px">
		<v-container
			v-resize="onResize"
			fluid
			:style="{ height: boxHeight + 'px', 'min-height': minHeight + 'px' }">
			<v-layout
				align-center
				justify-center
				row
				fill-height>
				<v-flex>
					<HelloWorld />
				</v-flex>
			</v-layout>
		</v-container>

		<Navigation />

		<v-container>
			<v-layout
				justify-center
				row>
				<v-flex
					sm12
					md10
					lg8
					xl6>
					<h2>Featured work</h2>
					<Work />
				</v-flex>
			</v-layout>

			<v-layout
				justify-center
				row>
				<v-flex
					sm12
					md10
					lg8
					xl6>
					<h2>Featured skills</h2>
					<Skills />
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
import HelloWorld from '../components/home/HelloWorld';
import Navigation from '../components/home/Navigation';
import Work from '../components/home/Work';
import Skills from '../components/home/Skills';
import { mapMutations } from 'vuex';

export default {
	components: {
		HelloWorld,
		Navigation,
		Work,
		Skills,
	},
	data: () => ({
		showNavigation: true,
		boxHeight: 0,
		minHeight: 0,
	}),
	computed: {},
	watch: {
		'$vuetify.breakpoint.width'() {
			this.onResize();
		},
	},
	mounted() {
		this.toggleToolbar(false);
		this.toggleBottomNav(false);
		this.onResize();
	},
	created: function () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed: function () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		...mapMutations([
			'TOGGLE_NAVIGATION',
			'TOGGLE_TOOLBAR',
			'TOGGLE_BOTTOMNAV',
		]),
		toggleNavigator: function (value) {
			this.TOGGLE_NAVIGATION(value);
		},
		toggleToolbar: function (value) {
			this.TOGGLE_TOOLBAR(value);
		},
		toggleBottomNav: function (value) {
			this.TOGGLE_BOTTOMNAV(value);
		},
		handleScroll: function () {
			const offsetTop =
				window.pageYOffset || document.documentElement.scrollTop;
			switch (true) {
				case offsetTop >= 850:
					this.toggleNavigator(true);
					this.toggleToolbar(true);
					this.toggleBottomNav(true);
					break;
				case offsetTop >= 300:
					this.toggleNavigator(false);
					this.toggleToolbar(true);
					this.toggleBottomNav(false);
					break;
				default:
					this.toggleNavigator(false);
					this.toggleToolbar(false);
					this.toggleBottomNav(false);
			}
		},
		onResize: function () {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					if (window.outerHeight != 0) {
						this.boxHeight = window.outerHeight - 90;
						this.minHeight = window.outerHeight - 90;
					} else {
						this.boxHeight = window.innerHeight - 90;
						this.minHeight = window.innerHeight - 90;
					}
					break;
				default:
					if (window.outerHeight != 0) {
						this.boxHeight = window.outerHeight - 230;
						this.minHeight = window.outerHeight - 230;
					} else {
						this.boxHeight = window.innerHeight - 120;
						this.minHeight = window.innerHeight - 120;
					}
			}
		},
	},
};
</script>
